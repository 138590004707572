.tile {
    display: flex;
    flex-direction: column;
    place-content: space-between;

    .tile-no-border {
        padding: 0;
        box-shadow: none;
    }

    .tile-header {
        margin-bottom: 20px;

        .tile-title {
            color: #253049;
            font-size: 16px;
            font-weight: bold;
            margin: 0;
        }

        .tile-subtitle {
            font-size: 14px;
            color: #8E9AB0;
            font-weight: 600;
        }
    }
}

.tile-with-buttons {
    .tile-header {
        display: flex;
        place-content: space-between;
        align-items: start;

        @media (max-width: 960px) {
            flex-direction: column;
        }

        .tile-subtitle {
            @media (max-width: 960px) {
                margin-bottom: 10px;
            }
        }

        .buttons {
            @media (max-width: 960px) {
                text-align: center;
            }
        }
    }

    .btn-group .btn {
        font-size: 16px;
        border: 1px solid #CED4DA;
        color: #54565B;
        line-height: 0px;

        &:focus {
            box-shadow: none !important;
        }

        &.active {
            font-weight: 600;
            color: #FFF !important;

            &.purple { background: $purple; }
            &.orange { background: $orange; }
            &.pink { background: $pink; }
            &.blue { background: $blue; }
            &.grey { background: $grey; }
            &.green { background: $green; }
            &.cyan { background: $cyan; }
        }
    }
}