#math-editor {
	.keyboard {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		border-radius: 10px;
		box-shadow: 0 1px 2px rgba(0,0,0,0.3);
		
		a {
			border-color: #D9D9D9;
			box-shadow: 0 1px 1px rgba(0,0,0,0.3);
			background-color: white;
			width: 45px;

			&:hover {
				box-shadow: 0 1px 3px rgba(0,0,0,0.7);
			}

			&:active {
				box-shadow: none;
				position: relative;
				top: 1px;
			}
		}
	}
	
}