.owl-nav {
	display: flex;
	place-content: space-between;
	
	.owl-prev,
	.owl-next {
		border: 1px solid #CCC !important;
		font-size: 50px !important;
		background: #FFF !important;
		box-shadow: 0 2px 2px rgba(0,0,0,0.3);
		border-radius: 50%;
		width: 42px;
		height: 42px;
		display: flex;
		justify-content: center;
		align-items: center;
		outline: none;
		margin-top: -28px;
		z-index: 1 !important;
		opacity: 0.5;

		&:active {
			box-shadow: none;
		}

		span {
			display: flex;
			line-height: 0;
			margin-top: -10px;
		}
	}

	.owl-prev { margin-left: -15px; }
	.owl-next { margin-right: -15px; }

	.disabled { opacity: 0.1; }
}